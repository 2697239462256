import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import { Helmet } from "react-helmet"
import { Container, Content } from "../components/Container"
import { H1, H2, H3, SubHeading, P } from "../components/Heading"
import { Pre, LineNo, Span } from "../components/Code"
import { GlobalStyle } from "../components/GlobalStyle"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/github"
import { NavBar } from "../components/NavBar"

export default ({ data }) => {
  const { frontmatter, htmlAst } = data.markdownRemark

  return (
    <>
      <GlobalStyle />

      <Helmet>
        <title>{frontmatter.title} - Techuid</title>
        <meta name="Description" content={frontmatter.description} />
        <html lang="en" />
      </Helmet>

      <NavBar />

      <Container>
        <Content>
          <H1>{frontmatter.title}</H1>
          <SubHeading>
            {frontmatter.date} · {readingTime(htmlAst)} min read
          </SubHeading>

          {renderAst(htmlAst)}
        </Content>
      </Container>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
      }
    }
  }
`

function readingTime(node) {
  return Math.ceil(countWords(node) / 100)
}

function countWords(node) {
  return aggregateWords(node).split(" ").length
}

function aggregateWords(node) {
  let strs = ""
  let approved = ["p", "h1", "h2", "h3", "h4", "h5", "strong", "ul", "li", "em"]

  if (!node) {
    return strs
  }

  if (node["tagName"] && !approved.includes(node["tagName"])) {
    return strs
  }

  if (node["type"] === "text" && node["value"]) {
    strs += node["value"]
  }
  let childNodes = node["children"]
  for (let k in childNodes) {
    strs += aggregateWords(childNodes[k])
  }
  return strs
}

function Code(props) {
  return (
    <Highlight
      {...defaultProps}
      theme={theme}
      code={props.children[0].trim()}
      language="jsx"
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {/*<LineNo>{i + 1}</LineNo>*/}
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Pre>
      )}
    </Highlight>
  )
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    p: P,
    h1: H1,
    h2: H2,
    h3: H3,
    code: Code,
    "highlight": Code,
  },
}).Compiler
