import styled from "styled-components"

export const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;

  overflow: auto;
  word-wrap: normal;
  white-space: pre;

  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
  }
`

export const Span = styled.span`
  text-align: left;
  margin: 1em 0;
  padding: 0.1em;

  overflow: auto;
  word-wrap: normal;
  white-space: pre;
`

export const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`
